import { render, staticRenderFns } from "./DimensionWidget.vue?vue&type=template&id=54f9f764&scoped=true"
import script from "./DimensionWidget.vue?vue&type=script&lang=js"
export * from "./DimensionWidget.vue?vue&type=script&lang=js"
import style0 from "./DimensionWidget.vue?vue&type=style&index=0&id=54f9f764&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54f9f764",
  null
  
)

export default component.exports
<template>
  <div>
    <!-- Tables -->
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">
            <p class="mb-2">Entity</p>
            <div class="ed-input">
              <input
                class="w-100"
                placeholder="search node..."
                type="text"
                v-model="entityInstance"
              />
            </div>
          </th>
          <th scope="col">
            <ed-dropdown
              :selected="selectedType"
              :listItems="propTypeList"
              :isSelected="updateType"
            />
            <div class="ed-input">
              <input
                class="filter-input w-100"
                placeholder="search properties..."
                type="text"
                v-model="searchInstance"
              />
            </div>
          </th>
          <th scope="col">
            Statement
            <div class="ed-input">
              <input
                class="filter-input w-100"
                placeholder="search in statements..."
                type="text"
                v-model="statementInstance"
              />
            </div>
          </th>
        </tr>
      </thead>
      <tbody v-if="!isLoading">
        <tr
          class="instance"
          @click="updateState(val)"
          v-for="(val, index) of instanceOfData"
          :key="index"
        >
          <th scope="row">{{ index + 1 }}</th>
          <td>{{ val.label }}</td>
          <td>{{ val.value }}</td>
          <td v-html="val.statement"></td>
        </tr>
      </tbody>
    </table>
    <div v-if="isLoading" class="d-flex justify-content-center w-100">
      <div>
        <img width="300px" src="@/assets/load.gif" v-if="isLoading" />
        <p>Please be patient, this might take few seconds</p>
      </div>
    </div>
  </div>
</template>

<script>
import graphReactor from '@/common/core/Graph/graphReactor';
import edDropdown from '@/common/components/ed-forms/ed-dropdown.vue';

export default {
  data() {
    return {
      isLoading: true,
      instanceOfData: null,
      entityInstance: '',
      selectedType: 'What',
      statementInstance: '',
      searchInstance: '',
      propTypeList: ['What', 'When', 'Where', 'Who', 'Which', 'How', 'Why'],
    };
  },

  components: {
    edDropdown,
  },

  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch('gmodule/dimensionDataFetch', this.selectedType);
    this.isLoading = false;
    this.instanceOfData = this.dimensionData;
  },

  methods: {
    async updateState(val) {
      const wordObj = {
        word: val.label,
        label: val.label,
        type: 'string',
        lang: this.$store.state.locale.currentLocale,
      };

      // this.$store.commit('SET_CURRENT_ENTITY', wordObj);
      // await this.$store.dispatch('setCurrentThought', wordObj);
      this.$store.dispatch('setCurrentThought', {
        thoughtObject: wordObj,
        excludeCallList: ['gmodule/exploreGraphFetch'],
      });
    },

    async updateDimnensionData() {
      await this.$store.dispatch(
        'gmodule/dimensionDataFetch',
        this.selectedType,
      );
      this.instanceOfData = this.dimensionData;
    },

    async updateType(tab) {
      this.selectedType = tab;
      this.isLoading = true;
      await this.updateDimnensionData();
      this.isLoading = false;
    },

    hslNode() {
      this.clearStates();

      const uniquArray = Array.from(
        new Set(this.instanceOfData.map((i) => i.label)),
      );

      graphReactor.exploreInstance.getNodes().forEach((node) => {
        graphReactor.exploreInstance.setItemState(node, 'opacityO', true);
        this.setOpacityLabel(node, 0.1);
        // }
      });
      graphReactor.exploreInstance.getEdges().forEach((edge) => {
        graphReactor.exploreInstance.setItemState(edge, 'opacityO', true);
        this.setOpacityLabel(edge, 0.1);
      });

      graphReactor.exploreInstance.findAll(
        'node',
        (node) => {
          if (uniquArray.includes(node.get('model').label)) {
            this.setOpacityLabel(node, 1);
            graphReactor.exploreInstance.setItemState(
              node.get('model').id,
              'focusEd',
              true,
            );
            return true;
          }
          return false;
        },
      );
    },

    setOpacityLabel(item, val) {
      graphReactor.exploreInstance.updateItem(item, {
        labelCfg: {
          style: {
            opacity: val,
          },
        },
      });
    },

    clearStates() {
      graphReactor.exploreInstance.getNodes().forEach((node) => {
        graphReactor.exploreInstance.clearItemStates(node);
        this.setOpacityLabel(node, 1);
      });
      graphReactor.exploreInstance.getEdges().forEach((edge) => {
        graphReactor.exploreInstance.clearItemStates(edge);
        this.setOpacityLabel(edge, 1);
      });

      graphReactor.exploreInstance.getCombos().forEach((combo) => {
        graphReactor.exploreInstance.clearItemStates(combo);
      });
    },
  },

  computed: {
    graphData() {
      return this.$store.state.gmodule.g6_explore_data;
    },
    dimensionData() {
      return this.$store.state.gmodule.dimension_data;
    },
  },

  watch: {
    async graphData() {
      this.isLoading = true;
      await this.updateDimnensionData();
      this.isLoading = false;
    },
    searchInstance(val) {
      this.instanceOfData = this.dimensionData.filter((i) => i.value.toLowerCase().includes(val.toLowerCase()));
      this.hslNode();

      if (val === '') {
        this.clearStates();
      }
    },
    entityInstance(val) {
      this.instanceOfData = this.dimensionData.filter((i) => i.label.toLowerCase().includes(val.toLowerCase()));
      this.hslNode();

      if (val === '') {
        this.clearStates();
      }
    },
    statementInstance(val) {
      this.instanceOfData = this.dimensionData.filter((i) => i.statement.toLowerCase().includes(val.toLowerCase()));
      this.hslNode();

      if (val === '') {
        this.clearStates();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.instance {
  cursor: pointer;
  &:hover {
    background-color: rgba(242, 242, 242, 0.832);
  }
}

input {
  border-radius: 0.3rem;
  border: none;
  border: 1px solid gray;
  width: 100%;
  border: none;
  padding: 0.5rem;
  &:focus {
    outline: none;
  }
}
</style>
